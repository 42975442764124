const ThemeFolkhem = () => (
    <>
        <style global jsx>{`
        @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,600i');

        @keyframes fadeOut {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        @keyframes slideUpFadeIn {
            0% {
                opacity: 0;
                transform: translate(0px, 40px);
            }
            100% {
                opacity: 1;
                transform: translate(0px, 0px);
            }
        }

        @keyframes slideDownFadeIn {
            0% {
                opacity: 0;
                transform: translate(0px, -40px);
            }
            100% {
                opacity: 1;
                transform: translate(0px, 0px);
            }
        }

        :root {
            --fontFamilyBase: 'Source Sans Pro', sans-serif;
            --fontBaseColor: rgba(0, 0, 0, 0.6);
            --backgroundColor: #fffbf7;
            --colorBrand: #236143;
            --colorBrandDark: #062f1b;
            --colorBrandDarkRGB: 6,47,27;
            --colorBlack: #000000;
            --colorBlackRGB: 0,0,0;
            --colorWhite: #fff;
            --colorSecondaryLight: #fffbf7;
            --colorGray30: #d9d9d9;
        }

        h1 {
            color: rgba(var(--colorBrandDarkRGB), 0.8);
            font-size: 3.4rem;
            font-weight: 300;
            line-height: 1.15;
            letter-spacing: 0.8px;
        }
        // @include media(md) {
        @media (min-width: 832px) {
            h1 {
                font-size: 4.8rem;
            }
        }

        h2 {
            color: rgba(var(--colorBrandDarkRGB), 0.8);
            font-size: 3.4rem;
            font-weight: 300;
            line-height: 1.15;
            letter-spacing: 0.8px;
        }
        //@include media(md) {
        @media (min-width: 832px) {
            h2 {
                font-size: 4.8rem;
            }
        }

        h3 {
            color: rgba(var(--colorBrandDarkRGB), 0.8);
            font-size: 3rem;
            font-weight: 300;
            line-height: 1.35;
            letter-spacing: 0.4px;
        }
        //@include media(lg) {
        @media (min-width: 1164px) {
            h3 {
                font-size: 3.4rem;
            }
        }

        h4 {
            color: rgba(var(--colorBrandDarkRGB), 0.8);
            font-size: 2.2rem;
            font-weight: 300;
            line-height: 1.35;
            letter-spacing: 0.4px;
        }
        //@include media(lg) {
        @media (min-width: 1164px) {
            h4 {
                font-size: 2.4rem;
            }
        }

        h5 {
            color: rgba(var(--colorBrandDarkRGB), 0.8);
            font-size: 1.8rem;
            font-weight: 300;
            line-height: 1.2;
            letter-spacing: 0.4px;
        }
        // @include media(lg) {
        @media (min-width: 1164px) {
            h5 {
                font-size: 2rem;
            }
        }

        h6 {
            color: rgba(var(--colorBrandDarkRGB), 0.8);
            font-size: 1.6rem;
            line-height: 1.2;
            letter-spacing: 0.4px;
        }
        // @include media(lg) {
        @media (min-width: 1164px) {
            h6 {
                font-size: 1.8rem;
            }
        }

        p {
            font-size: 1.6rem;
            line-height: 1.5;
            letter-spacing: 0.4px;
            color: rgba(var(--colorBlackRGB), 0.6);
        }
        @media print {
            p {
                font-size: 1.4rem;
                line-height: 1.5;
            }
        }

        a {
            color: var(--colorBrand);
            text-decoration: underline;
        }

        .Wysiwyg a:hover {
            color: var(--colorBrandDark);
        }

        b {
            font-weight: 600;
        }

        i {
            font-style: italic;
        }

        // Wysiwyg
        .Wysiwyg h1 {
            margin-bottom: 16px;
        }

        .Wysiwyg h2 {
            margin-bottom: 16px;
        }

        .Wysiwyg h3 {
            margin-bottom: 16px;
        }

        .Wysiwyg h4 {
            margin-bottom: 16px;
        }

        .Wysiwyg h5 {
            margin-bottom: 8px;
        }

        .Wysiwyg h6 {
            margin-bottom: 8px;
        }

        .Wysiwyg p {
            line-height: 1.5;
            margin-bottom: 24px;
            color: rgba(var(--colorBlackRGB), 0.6);
        }
        @media (min-width: 832px) {
            .Wysiwyg p {
                margin-bottom: 36px;
            }
        }

        .Wysiwyg ul {
            max-width: 550px;
            margin-bottom: 64px;
        }
        @media (min-width: 832px) {
            .Wysiwyg ul {
                margin-bottom: 80px;
            }
        }

        .Wysiwyg ul li {
            margin-bottom: 15px;
            line-height: 1.5;
            position: relative;
            padding-left: 20px;
        }

        .Wysiwyg ul li:before {
            left: 0;
            content: '';
            display: block;
            min-width: 6px;
            height: 6px;
            width: 6px;
            position: absolute;
            margin-top: 12px;
            margin-right: 15px;
            background-color: var(--colorBrand);
        }

        .Wysiwyg ol {
            max-width: 550px;
            counter-reset: olCounter;
            margin-bottom: 64px;
        }
        @media (min-width: 832px) {
            .Wysiwyg ol {
                margin-bottom: 80px;
            }
        }

        .Wysiwyg ol li {
            display: flex;
            margin-bottom: 15px;
            line-height: 1.5;
        }
        .Wysiwyg ol li:before {
            content: counter(olCounter) '.';
            display: block;
            min-width: 16px;
            font-weight: 400;
            margin-right: 5px;
            color: var(--colorBrand);
            font-size: 1.6rem;
            counter-increment: olCounter;
        }
        `}</style>
        <RcSliderTheme />
    </>
);

const RcSliderTheme = () => (
    <style global jsx>{`
    .rc-slider {
        position: relative;
        box-sizing: border-box;
        width: calc(100% - 24px);
        height: 24px;
        margin-left: 12px;
        padding: 12px 0;
        border-radius: 6px;

        -ms-touch-action: none;
        touch-action: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider * {
        box-sizing: border-box;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider-rail {
        position: absolute;
        right: -12px;
        left: -12px;
        height: 2px;
        border-radius: 6px;
        background-color: var(--colorBlack);
        opacity: 0.08;
    }

    .rc-slider-track {
        position: absolute;
        left: 0;
        height: 2px;
        border-radius: 6px;
        background-color: var(--colorBlack);
        opacity: 0.16;
    }

    .rc-slider-handle {
        position: absolute;
        width: 24px;
        height: 24px;
        margin-top: -11px;
        margin-left: -12px;
        border-radius: 4px;
        background-color: var(--colorBrand);
        cursor: pointer;
        cursor: -webkit-grab;
        cursor: grab;

        -ms-touch-action: pan-x;
        touch-action: pan-x;
    }

    .rc-slider-handle:hover {
        border-color: var(--colorSecondaryLight);
    }

    .rc-slider-handle:active {
        border-color: var(--colorSecondaryLight);
        cursor: -webkit-grabbing;
        cursor: grabbing;
    }

    .rc-slider-handle:focus {
        border-color: var(--colorSecondaryLight);
        outline: none;
    }

    .rc-slider-mark {
        position: absolute;
        top: 18px;
        left: 0;
        width: 100%;
        font-size: 12px;
    }

    .rc-slider-mark-text {
        position: absolute;
        display: inline-block;
        color: #999;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
    }

    .rc-slider-mark-text-active {
        color: #666;
    }

    .rc-slider-step {
        position: absolute;
        width: 100%;
        height: 2px;
        background: transparent;
    }

    .rc-slider-dot {
        position: absolute;
        bottom: -2px;
        width: 8px;
        height: 8px;
        margin-left: -4px;
        vertical-align: middle;
        border: 2px solid var(--colorGray30);
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;
    }

    .rc-slider-dot-active {
        border-color: #96dbfa;
    }

    .rc-slider-disabled {
        background-color: var(--colorGray30);
    }

    .rc-slider-disabled .rc-slider-track {
        background-color: #ccc;
    }

    .rc-slider-disabled .rc-slider-handle,
    .rc-slider-disabled .rc-slider-dot {
        border-color: #ccc;
        background-color: #fff;
        cursor: not-allowed;
    }

    .rc-slider-disabled .rc-slider-mark-text,
    .rc-slider-disabled .rc-slider-dot {
        cursor: not-allowed !important;
    }

    .rc-slider-vertical {
        width: 14px;
        height: 100%;
        padding: 0 5px;
    }

    .rc-slider-vertical .rc-slider-rail {
        width: 2px;
        height: 100%;
    }

    .rc-slider-vertical .rc-slider-track {
        bottom: 0;
        left: 5px;
        width: 2px;
    }

    .rc-slider-vertical .rc-slider-handle {
        margin-bottom: -7px;
        margin-left: -5px;

        -ms-touch-action: pan-y;
        touch-action: pan-y;
    }

    .rc-slider-vertical .rc-slider-mark {
        top: 0;
        left: 18px;
        height: 100%;
    }

    .rc-slider-vertical .rc-slider-step {
        width: 2px;
        height: 100%;
    }

    .rc-slider-vertical .rc-slider-dot {
        left: 2px;
        margin-bottom: -4px;
    }

    .rc-slider-vertical .rc-slider-dot:first-child {
        margin-bottom: -4px;
    }

    .rc-slider-vertical .rc-slider-dot:last-child {
        margin-bottom: -4px;
    }

    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
        display: block !important;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;

        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .rc-slider-tooltip-zoom-down-leave {
        display: block !important;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;

        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
    .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
        -webkit-animation-name: rcSliderTooltipZoomDownIn;
        animation-name: rcSliderTooltipZoomDownIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
        -webkit-animation-name: rcSliderTooltipZoomDownOut;
        animation-name: rcSliderTooltipZoomDownOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    .rc-slider-tooltip-zoom-down-leave {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    @-webkit-keyframes rcSliderTooltipZoomDownIn {
        0% {
            opacity: 0;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
        100% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
    }

    @keyframes rcSliderTooltipZoomDownIn {
        0% {
            opacity: 0;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
        100% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
    }

    @-webkit-keyframes rcSliderTooltipZoomDownOut {
        0% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
        100% {
            opacity: 0;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
    }

    @keyframes rcSliderTooltipZoomDownOut {
        0% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
        100% {
            opacity: 0;
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
        }
    }

    .rc-slider-tooltip {
        position: absolute;
        top: -9999px;
        left: -9999px;
        visibility: visible;
        box-sizing: border-box;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider-tooltip * {
        box-sizing: border-box;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .rc-slider-tooltip-hidden {
        display: none;
    }

    .rc-slider-tooltip-placement-top {
        padding: 4px 0 8px 0;
    }

    .rc-slider-tooltip-inner {
        min-width: 24px;
        height: 24px;
        padding: 6px 2px;
        color: #fff;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        border-radius: 6px;
        background-color: #6c6c6c;
    }

    .rc-slider-tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
    }

    .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
        bottom: 4px;
        left: 50%;
        margin-left: -4px;
        border-width: 4px 4px 0;
        border-top-color: #6c6c6c;
    }
    `}</style>
);

export default ThemeFolkhem;

